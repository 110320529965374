const softwares_uri = {
  "Jitsi Meet": "https://jitsi.org/",
  CodiMD: "https://demo.codimd.org/",
  Etherpad: "https://etherpad.org/",
  Ethercalc: "https://ethercalc.net/",
  Framadate: "https://framagit.org/framasoft/framadate/framadate",
  OpenSondage: "https://framagit.org/framasoft/framadate/framadate",
  Lufi: "https://framagit.org/luc/lufi",
  Lutim: "https://github.com/ldidry/lutim",
  LSTU: "https://github.com/ldidry/lstu",
  Scrumblr: "https://github.com/aliasaria/scrumblr",
  "rs-short": "https://git.42l.fr/42l/rs-short",
  Polr: "https://polrproject.org/",
  Libreto: "https://www.libretro.com/",
  Cryptpad: "https://cryptpad.fr/",
  Plik: "https://github.com/root-gg/plik/",
  Filetolink: "https://framagit.org/kepon/file2link",
  FirefoxSend: "https://send.firefox.com/",
  Privatebin: "https://privatebin.info/",
  HedgeDoc: "https://hedgedoc.org/",
  Yourls: "https://yourls.org",
  Jirafeau: "https://gitlab.com/mojo42/Jirafeau",
  MyPads: "https://framagit.org/framasoft/Etherpad/ep_mypads"
}
export default softwares_uri
